import {
  configureSendContactFormService,
  ContactFormReturnType,
  ContactFormDataType,
} from './send-contact-form.service'

export type LiftedServicesType = {
  sendHomeForm: (
    formData: ContactFormDataType,
  ) => Promise<ContactFormReturnType>
  sendDevelopmentsForm: (
    formData: ContactFormDataType,
  ) => Promise<ContactFormReturnType>
  sendContactForm: (
    formData: ContactFormDataType,
  ) => Promise<ContactFormReturnType>
  sendRegisterForm: (
    formData: ContactFormDataType,
  ) => Promise<ContactFormReturnType>
  sendScheduleForm: (
    formData: ContactFormDataType,
  ) => Promise<ContactFormReturnType>
  sendDetailsForm: (
    formData: ContactFormDataType,
  ) => Promise<ContactFormReturnType>
}

export const configureLiftedServices: (
  HOME_FORM_URL: string,
  DEVELOPMENTS_FORM_URL: string,
  REGISTER_FORM_URL: string,
  CONTACT_FORM_URL: string,
  SCHEDULE_FORM_URL: string,
  DETAILS_FORM_URL: string,
) => LiftedServicesType = (
  HOME_FORM_URL,
  DEVELOPMENTS_FORM_URL,
  REGISTER_FORM_URL,
  CONTACT_FORM_URL,
  SCHEDULE_FORM_URL,
  DETAILS_FORM_URL,
) => ({
  sendHomeForm: configureSendContactFormService(HOME_FORM_URL),
  sendDevelopmentsForm: configureSendContactFormService(DEVELOPMENTS_FORM_URL),
  sendRegisterForm: configureSendContactFormService(REGISTER_FORM_URL),
  sendContactForm: configureSendContactFormService(CONTACT_FORM_URL),
  sendScheduleForm: configureSendContactFormService(SCHEDULE_FORM_URL),
  sendDetailsForm: configureSendContactFormService(DETAILS_FORM_URL),
})
