export const gmapsBoundsToArray: (
  bounds: google.maps.LatLngBounds,
) => [number, number][] = bounds => {
  const ne = {
    lat: bounds.getNorthEast().lat(),
    lng: bounds.getNorthEast().lng(),
  }
  const sw = {
    lat: bounds.getSouthWest().lat(),
    lng: bounds.getSouthWest().lng(),
  }
  const se = { lat: sw.lat, lng: ne.lng }
  const nw = { lat: ne.lat, lng: sw.lng }
  const b = [ne, se, sw, nw].map(p => [p.lng, p.lat])
  console.log(b)

  return b
}

export const gmapsBoundsToRepliersMapString: (
  bounds: google.maps.LatLngBounds,
) => string = bounds =>
  `[[${gmapsBoundsToArray(bounds)
    .map(p => `[${p[0]},${p[1]}]`)
    .join(',')}]]`
