import { RepliersServicesType } from './repliers-services.type'
import { configureGetListingsService } from './get-listings.service'
import { configureGetListingDetailService } from './get-listing-detail.service'
import { configureGetTeamMembersService } from './get-team-members.service'
import { configureCreateClientService } from './create-client.service'
import { configureCreateSearchService } from './create-search.service'

export const configureRepliersServices: (
  BASE_URL: string,
  API_KEY: string,
) => RepliersServicesType = (BASE_URL, API_KEY) => ({
  getListings: configureGetListingsService(BASE_URL, API_KEY),
  getListingDetail: configureGetListingDetailService(BASE_URL, API_KEY),
  getTeamMembers: configureGetTeamMembersService(BASE_URL, API_KEY),
  createClient: configureCreateClientService(BASE_URL, API_KEY),
  createSearch: configureCreateSearchService(BASE_URL, API_KEY),
})

export const MISSISSAUGA_BOUNDS = [
  [-79.8060608, 43.5882743],
  [-79.6735382, 43.5012457],
  [-79.648304, 43.5231582],
  [-79.6088219, 43.4953292],
  [-79.5293427, 43.5793943],
  [-79.5671082, 43.6086307],
  [-79.571228, 43.6320034],
  [-79.6309662, 43.7299259],
  [-79.8046875, 43.5882344],
]

export const VAUGHAN_BOUNDS = [
  [-79.639077, 43.749765],
  [-79.420723, 43.798355],
  [-79.428276, 43.834028],
  [-79.453682, 43.828084],
  [-79.477715, 43.922622],
  [-79.710488, 43.875124],
  [-79.639077, 43.749765],
]

export const WHITBY_BOUNDS = [
  [-79.047153, 44.008585],
  [-78.970295, 43.833607],
  [-78.882, 43.852415],
  [-78.960225, 44.028287],
  [-79.047153, 44.008585],
]

export const NORTHYORK_BOUNDS = [
  [-79.3417147, 43.8151567],
  [-79.579678, 43.762482],
  [-79.583403, 43.753031],
  [-79.551407, 43.743854],
  [-79.539373, 43.709897],
  [-79.524439, 43.705896],
  [-79.505705, 43.709905],
  [-79.502258, 43.697662],
  [-79.506305, 43.696362],
  [-79.497462, 43.692569],
  [-79.467488, 43.698745],
  [-79.469136, 43.711097],
  [-79.443707, 43.715964],
  [-79.438441, 43.705417],
  [-79.42725, 43.708113],
  [-79.433394, 43.735944],
  [-79.408709, 43.75354],
  [-79.403771, 43.731108],
  [-79.396194, 43.732485],
  [-79.3867779, 43.72829],
  [-79.3818426, 43.7273516],
  [-79.3795252, 43.7250265],
  [-79.378359, 43.718403],
  [-79.364548, 43.720397],
  [-79.351933, 43.716368],
  [-79.33066, 43.7062],
  [-79.298999, 43.715868],
  [-79.3417147, 43.8151567],
]

export const TORONTO_BOUNDS = [
[-79.5431348, 43.5826496],
[-79.3429296, 43.5799451],
[-79.1175275, 43.7942699],
[-79.1371822, 43.8056682],
[-79.1703299, 43.8553942],
[-79.6422958, 43.7554730],
[-79.5877798, 43.6661241],
[-79.6077051, 43.6468117],
[-79.5911397, 43.6444516],
[-79.5796949, 43.6265523],
[-79.5636412, 43.6269870],
[-79.5594426, 43.6048417],
[-79.5431348, 43.5826496],
]

export const GEORGETOWN_BOUNDS = [
  [-79.9801254, 43.6527219],
  [-79.9008179, 43.5967236],
  [-79.8348999, 43.6384979],
  [-79.9188423, 43.6977282],
  [-79.9801254, 43.6527219],
]

export const GUELPH_BOUNDS = [
  [-80.3330612, 43.5362261],
  [-80.3124619, 43.505094],
  [-80.1878357, 43.4549998],
  [-80.1442337, 43.4886497],
  [-80.1476669, 43.5656001],
  [-80.1960754, 43.5919747],
  [-80.2654266, 43.5897358],
  [-80.3330612, 43.5362261],
]

export const RICHMOND_HILL_BOUNDS = [
  [-79.4867706, 43.9574846],
  [-79.4524384, 43.8276112],
  [-79.367981, 43.8387605],
  [-79.3920135, 43.976345],
  [-79.4857407, 43.95731],
]

export const AJAX_BOUNDS = [
  [-78.970908, 43.833602],
  [-79.008684, 43.922188],
  [-79.087181, 43.903987],
  [-79.070255, 43.854655],
  [-79.06633, 43.854655],
  [-79.061179, 43.842095],
  [-79.051858, 43.845633],
  [-79.038121, 43.815904],
  [-79.020195, 43.815701],
  [-79.0037155, 43.8210266],
  [-78.9829445, 43.8262293],
  [-78.970908, 43.833602],
]

export const OAKVILLE_BOUNDS = [
  [-79.640634, 43.4686184],
  [-79.7278226, 43.5263975],
  [-79.8122651, 43.4608938],
  [-79.7123758, 43.3893314],
  [-79.640634, 43.4683693],
]
export const MILTON_BOUNDS = [
  [-80.0367614, 43.5216677],
  [-79.8376694, 43.4010565],
  [-79.728512, 43.5231614],
  [-79.8060893, 43.5838726],
  [-79.8534595, 43.6151996],
  [-80.0360749, 43.5196761],
]

export const BRAMPTON_BOUNDS = [
  [-79.8887462, 43.6748471],
  [-79.7898478, 43.6022638],
  [-79.7090811, 43.6482582],
  [-79.6682882, 43.7059748],
  [-79.6390052, 43.74936],
  [-79.695425, 43.8483319],
  [-79.8887462, 43.6748471],
]

export const WHITCHURCH_STOUFFVILLE_BOUNDS = [
  [-79.387207, 43.9376961],
  [-79.2251587, 43.9693458],
  [-79.2704773, 44.077026],
  [-79.4146729, 44.0454338],
  [-79.3858337, 43.9386854],
]

export const AURORA_BOUNDS = [
  [-79.5671082, 43.9196581],
  [-79.389267, 43.9524551],
  [-79.4119263, 44.0298625],
  [-79.5842743, 43.9967657],
  [-79.5856476, 43.9969466],
  [-79.5684814, 43.9207964],
]

export const NEW_MARKET_BOUNDS = [
  [-79.5780945, 44.004066],
  [-79.4023132, 44.0356804],
  [-79.4297791, 44.1185878],
  [-79.6316528, 44.085044],
  [-79.5835876, 44.0030778],
]

export const MARKHAM_BOUNDS = [
[-79.4287290, 43.8345630],
[-79.4199052, 43.7980722],
[-79.3902236, 43.8046032],
[-79.3553056, 43.8124432],
[-79.3364080, 43.8168765],
[-79.3032295, 43.8245837],
[-79.3291290, 43.8185442],
[-79.1936771, 43.8497890],
[-79.1852763, 43.8518816],
[-79.1702407, 43.8554425],
[-79.1896692, 43.8991601],
[-79.2089490, 43.9414974],
[-79.2161747, 43.9589146],
[-79.2161747, 43.9589146],
[-79.2174147, 43.9625146],
[-79.2535141, 43.9547458],
[-79.2536798, 43.9554500],
[-79.2781504, 43.9496284],
[-79.3028823, 43.9438764],
[-79.3026803, 43.9431075],
[-79.3254104, 43.9383269],
[-79.3532685, 43.9319547],
[-79.3530975, 43.9312565],
[-79.3530975, 43.9312565],
[-79.3745462, 43.9263566],
[-79.3895532, 43.9231616],
[-79.3872284, 43.9136916],
[-79.3776797, 43.8746169],
[-79.3712910, 43.8486605],
[-79.3708618, 43.8469972],
[-79.3768971, 43.8456524],
[-79.3791647, 43.8451246],
[-79.3822218, 43.8444215],
[-79.3950900, 43.8414693],
[-79.4003565, 43.8402603],
[-79.4062138, 43.8400953],
[-79.4065736, 43.8400810],
[-79.4064590, 43.8396304],
[-79.4064355, 43.8395265],
[-79.4179475, 43.8370236],
[-79.4197674, 43.8366167],
[-79.4234452, 43.8357945],
[-79.4287290, 43.8345630],
]

export const SCARBOROUGH_BOUNDS = [
  [-79.3487549, 43.8146974],
  [-79.2747838, 43.6746297],
  [-79.1120707, 43.797224],
  [-79.1724874, 43.8225058],
  [-79.1801834, 43.8533444],
  [-79.3487549, 43.8146974],
]

export const ETOBICOKE_BOUNDS = [
  [-79.540329, 43.5852779],
  [-79.4778442, 43.6330266],
  [-79.5595551, 43.7591791],
  [-79.6151733, 43.7517365],
  [-79.540329, 43.5852779],

  // [-79.5417023, 43.5837309],
  // [-79.561615, 43.6092344],
  // [-79.6048737, 43.6484964],
  // [-79.5856476, 43.6637961],
  // [-79.6378326, 43.7487112],
  // [-79.5808411, 43.7656402],
  // [-79.5842743, 43.7551618],
  // [-79.5568085, 43.7403445],
  // [-79.549942, 43.7442433],
  // [-79.538269, 43.7273679],
  // [-79.5389557, 43.7164523],
  // [-79.5307159, 43.7025488],
  // [-79.519043, 43.6965892],
  // [-79.50737, 43.6732417],
  // [-79.5142365, 43.6628069],
  // [-79.5032501, 43.662409],
  // [-79.4991302, 43.6558493],
  // [-79.4908905, 43.6478968],
  // [-79.4750977, 43.6314915],
  // [-79.4606781, 43.6205522],
  // [-79.5417023, 43.5832439],
]

export const PICKERING_BOUNDS = [
[-79.1171120, 43.7951390],
[-79.0646340, 43.8023420],
[-79.0393130, 43.8167170],
[-79.0524800, 43.8459430],
[-79.0609200, 43.8425340],
[-79.0700360, 43.8544650],
[-79.0862420, 43.9038660],
[-79.0092650, 43.9223510],
[-79.0487660, 44.0103170],
[-79.2202770, 43.9704810],
[-79.1519890, 43.8133740],
[-79.1171120, 43.7951390],
]

export const KINGCITY_BOUNDS = [
[-79.5610800, 43.9023410],
[-79.4802280, 43.9199010],
[-79.4917290, 43.9563670],
[-79.5706272, 43.9419734],
[-79.5610800, 43.9023410],
]

export const STCATHARINES_BOUNDS = [
[-79.3236840, 43.1837890],
[-79.3103870, 43.1076670],
[-79.2749540, 43.1092320],
[-79.2741970, 43.1046750],
[-79.1772950, 43.1409320],
[-79.1784650, 43.1496370],
[-79.1919670, 43.1452710],
[-79.2026150, 43.2268700],
[-79.2765270, 43.2318900],
[-79.3236840, 43.1837890],
]

export { RepliersServicesType }
