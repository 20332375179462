import React from 'react'
import { Header } from './Header'
import { Footer2 } from './Footer2'
import '../global.css'
import '../fonts.css'
import { services } from '@services'
import { navigate } from 'gatsby'
import { LoadScript } from '@react-google-maps/api'
import { appConfig } from 'config'
import { FlexDiv } from 'design-system/FlexDiv'
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from 'react-icons/fa'

export const AuthContext = React.createContext<firebase.User | undefined>(
  undefined,
)

type EllicotLayoutProps = {}

export const EllicotLayout: React.FunctionComponent<EllicotLayoutProps> = props => {
  const [user, setUser] = React.useState<firebase.User>()
  const handleLogout = () => services.firebase.auth().signOut().then(clearUser)
  let resolvedUID = null

  const clearUser = () => {
    setUser(undefined)
    if (localStorage) localStorage.removeItem('user')
  }

  const persistUser = (u: firebase.User) => {
    setUser(u)
    if (localStorage) localStorage.setItem('user', u.uid)
  }

  React.useEffect(() => {
    return services.firebase.auth().onAuthStateChanged(u => {
      if (u) {
        persistUser(u)
        resolvedUID = u.uid
      } else {
        clearUser()
        resolvedUID = null
      }
    })
  }, [])

  // const authRedirect = checkAuthStateForRedirect(resolvedUID)
  const renderContent = () => {
    return (
      <AuthContext.Provider value={user}>
        <div style={{ width: '100%', height: '100%', padding: 0 }}>
          <div className="parralaxWrapper">
            <Header onLogout={() => handleLogout().then(() => navigate('/'))} />
            {props.children}
          </div>
          <Footer2 />
          <FlexDiv></FlexDiv>
          <FlexDiv
            style={{
              display: 'flex',
              background: '#f4f4f4',
              flexDirection: 'column',
              placeContent: 'center',
            }}
            className="footerCopyParallax"
          >
            <p className="copyrightText copyText23">
              Not intended to solicit properties currently listed for sale. The
              trademarks REALTOR®, REALTORS® and the REALTOR® logo are
              controlled by the Canadian Real Estate Association (CREA) and
              identify real estate professionals who are members of CREA. The
              trademarks MLS®, Multiple Listing Service® and the associated
              logos are owned by CREA and identify the quality of services
              provided by real estate professionals who are members of CREA.
            </p>

            <div
              style={{
                padding: '0.5px',
                background: 'rgb(0 0 0 / 10%)',
                marginBottom: '1.5em',
                maxWidth: '1100px',
              }}
              className="footerBreak"
            ></div>
            <p className="copyrightText copyText23 copyText45">
              Copyright © 2021 Ellicott Realty Inc., Brokerage &nbsp; &nbsp; |
              &nbsp; &nbsp; Independently Owned and Operated &nbsp; &nbsp;
            </p>
            <div className="socialWrapper desktopOnly">
              <a target="_blank" href="https://www.instagram.com/ellicottrealty/">
                <FaInstagram />
              </a>
              <a target="_blank" href="https://www.facebook.com/ellicottrealty/">
                <FaFacebook />
              </a>
              <a target="_blank" href="https://ca.linkedin.com/company/ellicott-realty-brokerage-inc">
                <FaLinkedin />
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCDlQ7IvLOGCaOwohM3bnNng">
                <FaYoutube />
              </a>
            </div>
          </FlexDiv>
          <FlexDiv className="mobileOnly lowerFooterWrap">
            <p>
              Copyright © 2021 Ellicott Realty Inc., Brokerage | Independently
              Owned and Operated
            </p>
            <div className="socialWrapper mobileOnly">
              <a href="https://www.instagram.com/ellicottrealty/">
                <FaInstagram />
              </a>
              <a href="https://www.facebook.com/ellicottrealty/">
                <FaFacebook />
              </a>
              <a href="https://ca.linkedin.com/company/ellicott-realty-brokerage-inc">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCDlQ7IvLOGCaOwohM3bnNng">
                <FaYoutube />
              </a>
            </div>
          </FlexDiv>
        </div>
      </AuthContext.Provider>
    )
  }
  return typeof google !== 'undefined' ? (
    renderContent()
  ) : (
    <LoadScript
      libraries={['places']}
      googleMapsApiKey={appConfig.gmaps.API_KEY}
    >
      {renderContent()}
    </LoadScript>
  )
}
