import axios from 'axios'
import { TeamMember } from './repliers-services.type'

export const configureGetTeamMembersService = (
  BASE_URL: string,
  API_KEY: string,
) => () => {
  const headers = {
    'content-type': 'application/json',
    'REPLIERS-API-KEY': API_KEY,
  }

  return axios
    .get<{ agents: TeamMember[] }>(`${BASE_URL}/agents`, { headers })
    .then(res => res.data.agents)
}
