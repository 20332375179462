import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import EllicottSVG from '../images/ellicott-tag.png'
import starIcon from '../assets/icons/starIcon.svg'
import { ToastContainer, toast } from 'react-toastify'
import EllicottMobile from '../images/Ellicott-Realty---Mobile-Logo.png'

import Modal from 'react-modal'
import { LoginComponentProps } from 'components/LoginComponent'
import { SignUp, SignupComponentProps } from 'components/SignUp'

import { AuthContext } from './EllicotLayout'
import { services } from '@services'
import { slide as Menu } from 'react-burger-menu'
import { appConfig } from 'config'
import 'react-toastify/dist/ReactToastify.css'


const Logo = styled.img`
  height: 3.5em;
  margin-top: 2em;
`

const LogoName = styled.img`
  max-height: 8em;
  margin-top: 4.5em !important;
`

const Bar = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  // margin: auto;
  text-decoration: none;
`

const Links = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.3px;
  cursor: pointer;

  @media (max-width: 1515px) {
    font-size: 14.5px;
  }
  @media (max-width: 1360px) {
    font-size: 14px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1100px) {
    font-size: 11.5px;
  }
`

 const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
 }
  
type HeaderPropsType = {
  onLogout: () => void
}

export const Header: React.FunctionComponent<HeaderPropsType> = props => {
  const [loginIsOpen, setLoginIsOpen] = React.useState(false)
  const [signupIsOpen, setSignupIsOpen] = React.useState(false)

  const openLogin = () => {
    setLoginIsOpen(true)
  }

  const closeLogin = () => {
    setLoginIsOpen(false)
  }

  const openSignup = () => {
    setSignupIsOpen(true)
  }

  const closeSignup = () => {
    setSignupIsOpen(false)
  }

  const loginUser: LoginComponentProps['handleLoginUser'] = ({
    credentials,
    rememberMe,
  }) => {
    services.firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(closeLogin)
  }

  const createClientInRepliers = (input: {
    email: string
    fname: string
    lname: string
    phonenumber: string
    agreeToReceiveEmails?: boolean
  }) => {
    console.log(input)
    return services.repliers.createClient({
      agentId: parseInt(appConfig.repliers.DEFAULT_AGENT_ID, 10),
      fname: input.fname,
      lname: input.lname,
      phone: '1' + input.phonenumber,
      email: input.email,
      status: true,
      preferences: {
        email: true,
        sms: true,
        unsubscribe: false,
      },
    })
  }

  const createClientSearchInRepliers = (
    clientId: number,
    agentId: number,
    map: [number, number][],
    minPrice: number,
    maxPrice: number,
    minBeds: number,
    maxBeds: number,
    minBaths: number,
    maxBaths: number,
    keywords: string,
  ) =>
    services.repliers.createSearch({
      agentId,
      clientId,
      map: [map],
      type: 'sale',
      class: ['condo', 'residential'],
      minPrice,
      maxPrice,
      minBeds,
      maxBeds,
      minBaths,
      maxBaths,
      keywords,
    })

  const signupUser: SignupComponentProps['handleSignupUser'] = formData => {
    console.log(formData)
    services.firebase
      .auth()
      .createUserWithEmailAndPassword(formData.email, formData.password)
      .then(() => {
        createRepliersClientAndSearch(formData)
      })
      .then(() => {
        toast.success(
          'Preferred client registration success. Thank you for choosing Ellicot',
        )
        const propertyTypeStrings = formData.propertyTypes
          .map(e => e.label)
          .join(',')
        console.log(propertyTypeStrings)
        services.lifted.sendRegisterForm({
          ...formData,
          propertyTypes: propertyTypeStrings,
        })
      })
      .catch(err => {
        console.log(err)
        switch (err.code) {
          case 'auth/email-already-in-use':
            toast.error(
              'The email provided is already registered with us. If you believe this is an error please contact support',
            )

            break
          case 'auth/invalid-email':
            toast.error(
              'The provided value for the email user property is invalid. It must be a string email address.',
            )
          case 'auth/invalid-password':
            toast.error(
              'The provided value for the password user property is invalid. It must be a string with at least six characters.',
            )
          default:
            toast.error('Unexpected error please contact support')

            break
        }
      })
  }
  const dropdownRef = useRef(null)
  const dropDown = useRef()
  const [isActive, setIsActive] = useState(false)
  const onClickMenu = () => setIsActive(!isActive)

   useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside=(event) =>{
            if (dropDown.current && !dropDown.current.contains(event.target)) {
              // alert("You clicked outside of me!");
              setIsActive(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [dropDown])

  const createRepliersClientAndSearch: SignupComponentProps['handleSignupUser'] = formData => {
    
    return createClientInRepliers(formData)
      .then(c =>
        formData.bounds
          ? createClientSearchInRepliers(
              c.clientId,
              parseInt(appConfig.repliers.DEFAULT_AGENT_ID, 10),
              formData.bounds,
              formData.minPrice,
              formData.maxPrice,
              formData.beds,
              formData.beds,
              formData.baths,
              formData.baths,
              formData.keywords,
            )
          : Promise.resolve(c),
      )
      .then(() => setSignupIsOpen(false))
      // .catch(err => {
      //   toast.error(
      //     'We could not add you to our preferred client mailing list. Please login and try again.',
      //   )
      // })
  }

  

  return (
    <AuthContext.Consumer>
      {user => {
        console.log(user)
        return (
          <FlexDiv
            style={{ background: 'rgb(28, 28, 28)', flexFlow: 'column' }}
            className="headerWrapper"
          >
            
            <Menu htmlClassName={ "menuOpenHTML" }>
              <Link
                  to="/"
                  style={{ textDecoration: 'none', }}
                >
                HOME
                </Link>
              <Link
                  to="/about"
                  style={{ textDecoration: 'none', }}
                >
                ABOUT
                </Link>
              <Link
                  to="/properties"
                  style={{ textDecoration: 'none', }}
                >
                  Looking to Buy
                </Link>
                <Link
                  to="/advantage"
                  style={{ textDecoration: 'none', }}
                >
                Looking to Sell
                </Link>
                <Link
                  to="/developments"
                  style={{ textDecoration: 'none', }}
                >
                Developments
                </Link>
              <Link
                  to="/commercial"
                  style={{ textDecoration: 'none', }}
                >
                Commercial
                </Link>
              <Link
                  to="/team"
                  style={{ textDecoration: 'none', }}
                >
                Team
                </Link>
              <Link
                  to="/journal"
                  style={{ textDecoration: 'none', }}
                >
                  Journal
                </Link>
              <Link
                  to="/contact"
                  style={{ textDecoration: 'none', }}
                >
                  Contact
                </Link>
              
              <a className="menu-item" href="tel:416-203-7447" style={{ background: '#000', color: '#eee', padding: '15px', borderRadius: '2.5px'}}>416-203-7447</a>
            </Menu>
            {/* Starting AnnouncementBar */}
            <ToastContainer />
            <FlexDiv
              style={{ background: '#fff', placeContent: 'center',}}
              className="topHeaderNav"
            >
              
              <div> </div>
              <React.Fragment>
                
                <div
                  style={{
                    alignSelf: 'center',
                    fontSize: '0.75em',
                    textTransform: 'uppercase',
                    margin: 2.5,
                    fontFamily: 'CentraNo2-Medium',
                    letterSpacing: '0.75px',
                    cursor: 'pointer',
                    display: 'inline-flex',
                    transform: 'translateY(1.5px)'
                  }}
                  onClick={openSignup}
                  className="getNotifiedSignUp"
                >
                  <img src={starIcon} style={{ margin: 5, height: '17.5px', display: 'flex', transform: 'translateY(1.5px)' }} />{' '}
                  REGISTER FOR PROPERTY NOTIFICATIONS
                </div>
                <FlexDiv style={{ flex: 0 }}>
                  {/* {user && (
                    <Links
                      style={{
                        cursor: 'pointer',
                        color: '#fff',
                      }}
                      onClick={props.onLogout}
                    >
                      Logout
                    </Links>
                  )}
                  {!user && (
                    <>
                      <Links
                        onClick={openSignup}
                        className="registerHeaderLink"
                      >
                        Register
                      </Links>

                      <Modal isOpen={signupIsOpen} onRequestClose={closeSignup}>
                        <SignUp
                          handleSignupUser={signupUser}
                          setSignupIsOpen={setSignupIsOpen}
                        />
                      </Modal>
                    </>
                  )} */}
                </FlexDiv>
                {/* <FlexDiv style={{ flex: 0 }} className="loginHeaderLink">
                  <a
                    style={{
                      textDecoration: 'none',
                      marginLeft: '25px',
                      marginRight: '25px',
                      color: '#fff',
                    }}
                    href="http://login.ellicottrealty.ca"
                  >
                    <Links>Login</Links>
                  </a>
                </FlexDiv> */}

                <Modal isOpen={signupIsOpen} onRequestClose={closeSignup}
                  onAfterOpen={() => document.body.style.overflow = 'hidden'}
                  onAfterClose={() => document.body.style.overflow = 'unset'}
                  onAfterClose={scrollToTop}
                  className={"Modal23"}>
                  <SignUp
                    handleSignupUser={signupUser}
                    setSignupIsOpen={setSignupIsOpen}
                  />
                </Modal>
              </React.Fragment>
            </FlexDiv>
            {/* Turning Off AnnouncementBar */}

            <FlexDiv
              align="center"
              style={{
                width: '100%',
                backgroundColor: '#1c1c1c',
                maxWidth: '75%',
                margin: 'auto',
                padding: '15px 0 15px',
              }}
              className="headerContainer"
            >
              <FlexDiv
                container
                justify="flex-start"
                align="center"
                style={{
                  flex: '25%',
                }}
                className="logoContainer"
              >
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <LogoName src={EllicottSVG} className="desktopOnly" />
                </Link>
              </FlexDiv>

              <FlexDiv
                style={{
                  justifyContent: 'flex-end',
                  flex: '75%',
                }}
                className="desktopOnly headerLinks"
              >
                <a ref={dropDown}
                  onClick={onClickMenu}
                  className="aboutDropDownWrapper"
                  style={{ textDecoration: 'none', paddingRight: '35px' }}
                >
                  <Links>About<span className="dropdownMenuArrow"></span></Links>
                  <FlexDiv ref={dropdownRef} className={`dropdownMenu ${isActive ? 'active' : 'inactive'}`}>
                      <Link to="/about" style={{ color: 'inherit', textDecoration: 'none'}}><span>About Us</span></Link>
                      <Link to="/team" style={{ color: 'inherit', textDecoration: 'none'}}><span>Our Team</span></Link>
                    </FlexDiv>
                </a>
               
                <Link
                  to="/properties"
                  style={{ textDecoration: 'none', marginRight: '20px' }}
                >
                  <Links>LOOKING TO BUY</Links>
                </Link>
                <Link
                  to="/advantage"
                  style={{ textDecoration: 'none', marginRight: '20px' }}
                >
                  <Links>LOOKING TO SELL</Links>
                </Link>
                <Link
                  to="/developments"
                  style={{ textDecoration: 'none', marginRight: '20px' }}
                >
                  <Links> Developments</Links>
                </Link>
                <Link
                  style={{ textDecoration: 'none', marginRight: '20px' }}
                  to={'/commercial'}
                >
                  <Links> Commercial</Links>
                </Link>
                <Link
                  style={{ textDecoration: 'none', marginRight: '20px' }}
                  to={'/journal'}
                >
                  <Links> Journal</Links>
                </Link>
                {/* <Link
                style={{ textDecoration: 'none', marginRight: '20px' }}
                to={'/blog'}
              >
                <Links>Journal</Links>
              </Link> */}
                <Link
                  style={{ textDecoration: 'none', marginRight: '20px' }}
                  to={'/contact'}
                >
                  <Links>Contact</Links>
                </Link>
                <a href="tel:416-203-7447" className="ellicottTel">
                  (416) 203-7447
                </a>
                


                {/* {user && (
                  <Links
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={props.onLogout}
                  >
                    Logout
                  </Links>
                )}
                {!user && (
                  <>
                    <Links onClick={openSignup}>Register</Links>

                    <Modal isOpen={signupIsOpen} onRequestClose={closeSignup}>
                      <SignUp
                        handleSignupUser={signupUser}
                        setSignupIsOpen={setSignupIsOpen}
                      />
                    </Modal>
                  </>
                )} */}
              </FlexDiv>
             </FlexDiv>
            <FlexDiv className="logoMobileContainer">
              <Link to="/">
                {' '}
                <img src={EllicottMobile} />
              </Link>
            </FlexDiv>
          </FlexDiv>
        )
      }}
    </AuthContext.Consumer>
  )
}
