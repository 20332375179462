import React, { useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Input } from '../design-system/Input'
import { Link } from 'gatsby'
import closeIcon from '../assets/icons/close-icon.svg'
import { GeocoderInput, GeocoderLocation } from './GeocodeInput'
import { gmapsBoundsToArray } from 'utils/gmaps'
import MultiSelect from 'react-multi-select-component'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const CurrencyFormat = require('react-currency-format')
import Select, { components } from 'react-select'
import NumberFormat from 'react-number-format'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        className="dropdown-heading-dropdown-arrow gray"
      >
        <path d="M6 9L12 15 18 9"></path>
      </svg>
    </components.DropdownIndicator>
  )
}

const bedOptions = [
  { value: 1, label: '1', name: 'minBeds', type: 'number' },
  { value: 2, label: '2', name: 'minBeds', type: 'number' },
  { value: 3, label: '3', name: 'minBeds', type: 'number' },
  { value: 4, label: '4', name: 'minBeds', type: 'number' },
  { value: 5, label: '5', name: 'minBeds', type: 'number' },
  { value: 6, label: '6', name: 'minBeds', type: 'number' },
  { value: 7, label: '7', name: 'minBeds', type: 'number' },
  { value: 8, label: '8', name: 'minBeds', type: 'number' },
  { value: 9, label: '9', name: 'minBeds', type: 'number' },
  { value: 10, label: '10', name: 'minBeds', type: 'number' },
]

const bathOptions = [
  { value: 1, label: '1', name: 'minBaths', type: 'number' },
  { value: 2, label: '2', name: 'minBaths', type: 'number' },
  { value: 3, label: '3', name: 'minBaths', type: 'number' },
  { value: 4, label: '4', name: 'minBaths', type: 'number' },
  { value: 5, label: '5', name: 'minBaths', type: 'number' },
  { value: 6, label: '6', name: 'minBaths', type: 'number' },
  { value: 7, label: '7', name: 'minBaths', type: 'number' },
  { value: 8, label: '8', name: 'minBaths', type: 'number' },
  { value: 9, label: '9', name: 'minBaths', type: 'number' },
  { value: 10, label: '10', name: 'minBaths', type: 'number' },
]

const RadioLabel = styled.label<{ for: any }>`
  font-family: CentraNo2-Book;
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  color: white;
  -webkit-font-smoothing: antialiased;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 50%;
  cursor: pointer;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`

export type SignupComponentProps = {
  handleSignupUser: (input: {
    email: string
    message: string
    fullname: string
    phonenumber: string
    bounds?: [number, number][]
    keywords: string
    locationLabel: string
    minPrice: number
    maxPrice: number
    beds: number
    baths: number
    agreeToReceiveEmails: boolean
    tosAgreement: boolean
    propertyTypes: { label: string; value: string }[]
  }) => void
  setSignupIsOpen: (input: boolean) => void
}

type FormDataType = {
  email: string
  message: string
  fname: string
  lname: string
  phonenumber: string
  keywords: string
  locationLabel: string
  bounds?: [number, number][]
  minPrice: number
  maxPrice: number
  agreeToReceiveEmails: boolean
  tosAgreement: boolean
  propertyTypes: { label: string; value: string }[]
}

type FieldValidity = {
  [key in keyof Partial<FormDataType>]: boolean
}

export const SignUp: React.FC<SignupComponentProps> = props => {
  const [formValidity, setFormValidity] = React.useState(false)
  const [fieldValidity, setFieldValidity] = React.useState<FieldValidity>({
    email: false,
    // password: false,
    phonenumber: false,
    locationLabel: false,
    bounds: false,
    minPrice: false,
    maxPrice: false,
    tosAgreement: false,
  })
  const [selectedLocation, setSelectedLocation] = React.useState<
    GeocoderLocation
  >()

  const [formData, setFormData] = React.useState<FormDataType>({
    fname: '',
    message: '',
    lname: '',
    email: '',
    phonenumber: '',
    propertyTypes: [],
    locationLabel: '',
    minPrice: 0,
    maxPrice: 0,
    keywords: '',
    bounds: [],
    agreeToReceiveEmails: false,
    tosAgreement: false,
  })

  const handlePropertyTypesChange = (e: { label: string; value: string }[]) => {
    setFormData({
      ...formData,
      propertyTypes: e,
    })
  }

  React.useEffect(() => {
    const newValidity: FieldValidity = Object.keys(formData).reduce(
      (acc, k) => {
        const validator = validators[k as keyof FormDataType]
        return {
          ...acc,
          [k]: !validator ? true : validator(formData[k as keyof FormDataType]),
        }
      },
      {},
    )
    setFieldValidity(newValidity)
  }, [formData])

  React.useEffect(() => {
    let validity = true
    Object.keys(fieldValidity).forEach(k => {
      if (!fieldValidity[k as keyof FormDataType]) {
        validity = false
      }
    })
    setFormValidity(validity)
  }, [fieldValidity])

  React.useEffect(() => {
    if (selectedLocation) {
      handleChange([
        { name: 'locationLabel', value: selectedLocation.label },
        {
          name: 'bounds',
          value: gmapsBoundsToArray(selectedLocation.bounds),
        },
      ])
    } else {
      handleChange([{ name: 'locationLabel', value: '' }])
      handleChange([{ name: 'bounds', value: '' }])
    }
  }, [selectedLocation])

  const handleGeocoderSelection = (e: GeocoderLocation) => {
    if (!e) return
    console.log(e)
    setFormData({
      ...formData,
      locationLabel: e.label,
      bounds: e.customBounds ? e.customBounds : e.bounds,
    })
  }

  const validators: Partial<
    {
      [key in keyof FormDataType]: (value: any) => boolean
    }
  > = {
    //removed 1st digit 1.. is it firebase pass word req?
    phonenumber: (value: string) => !!/^([0-9]{10})$/.exec(value),
    email: (value: string) =>
      !!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.exec(
        value,
      ),
    // password: (value: string) => !!(value && value.length > 5),
    fullname: (value: string) => !!(value && value.length >= 3),
    locationLabel: (value: string) => !!(value && value.length),
    bounds: (value: string) => !!(value && value.length),
    keywords: (value: string) => true,
    minPrice: (value: number) => value >= 0,
    maxPrice: (value: number) => value > formData.minPrice,
    baths: (value: number) => value > 0,
    beds: (value: number) => value > 0,
    tosAgreement: (value: string) => !!value,
    agreeToReceiveEmails: (value: string) => !!value,
  }
  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    // alert('Select Handle Change')
    handleChange([
      {
        name: e.target.name,
        value:
          e.target.type === 'number' ? Number(e.target.value) : e.target.value,
      },
    ])
  }
  const handleSelect = (result: { name: string; value: string }) => {
    handleChange([
      {
        name: result.name,
        value: result.value === 'number' ? Number(result.value) : result.value,
      },
    ])
  }

  const handleChange = (
    input: {
      name: string
      value: string | boolean | number | [number, number][]
    }[],
  ) => {
    // console.log(input)

    const newData = input.reduce(
      (acc, item) => ({
        ...acc,
        [item.name as keyof FormDataType]: item.value,
      }),
      {},
    )
    const newForm = { ...formData, ...newData }
    setFormData(newForm)
    // console.log(newForm)
  }

  const signupButtonHandler = async (e: React.MouseEvent) => {
    e.preventDefault()
    try {
      const res = await fetch('/.netlify/functions/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      if (res.ok) {
        toast.success('Received. Elicott will send you a response shortly')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const options = [
    { label: 'Detached', value: 'Detached' },
    { label: 'Semi-Detached', value: 'Semi-Detached' },
    { label: 'Condo', value: 'Condo Apt' },
    { label: 'Condo Townhouse', value: 'Condo Townhouse' },
    { label: 'Freehold Townhouse', value: 'Att/Row/Twnhouse' },
  ]

  return (
    <div style={{ padding: 0 }} className="signUpWrapper">
      <FlexDiv
        className="closeContainer"
        onClick={() => {
          props.setSignupIsOpen(false)
        }}
        style={{ cursor: 'pointer' }}
      >
        <img src={closeIcon} />
      </FlexDiv>
      <FlexDiv className="popUpModalFormWrapper">
        <h1 className="contactTitle propertyMatch">
          Register for Property Notifcations
        </h1>
        <p style={{ textAlign: 'left' }} className="propertyMatchP">
          Your time is valuable so why waste it scouring the internet for just
          the right property? We make it so much easier. Just tell us what
          you’re looking for, and whether you're seeking a detached home, a
          townhouse, or a condo, and we’ll send you new listings daily, that
          meet your specific criteria. It’s that simple!
        </p>
        <ToastContainer />
        <FlexDiv style={{ flexDirection: 'column' }}>
          <FlexDiv
            style={{ marginTop: 10, flexWrap: 'nowrap' }}
            className="noWrapContact"
          >
            <Input
              label="First Name"
              name="fname"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '40px',
              }}
              onChange={handleChangeEvent}
            />
            <Input
              label="Last Name"
              name="lname"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '40px',
              }}
              onChange={handleChangeEvent}
            />
          </FlexDiv>
          <FlexDiv style={{ marginTop: 10 }} className="emailPhoneWrapper">
            <Input
              name="email"
              label="Email Address"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '40px',
              }}
              onChange={handleChangeEvent}
            />
            <NumberFormat
              // allowEmptyFormatting mask="_"
              name="phonenumber"
              placeholder={'Enter phone number'}
              format="(###) ###-####"
              className="contactFormInput phoneNumberSignUpInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '40px',
                width: '45.75%',
                paddingLeft: '15px',
              }}
              onChange={handleChangeEvent}
            />
          </FlexDiv>

          <MultiSelect
            options={options}
            value={formData.propertyTypes}
            onChange={handlePropertyTypesChange}
            labelledBy={'Select'}
            className="propMultiSelect"
            disableSearch
            overrideStrings={{ selectSomeItems: 'Select Property Type' }}
            selectAllLabel="Select all that apply"
          />
          <FlexDiv
            style={{ marginTop: 10, marginBottom: 10 }}
            className="signUpGeoInputWrapper"
          >
            <Input
              name="locationLabel"
              label="City, Neighborhood or Postal Code of Interest"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '40px',
              }}
              onChange={handleChangeEvent}
            />
            {/* <GeocoderInput
              onNewSelection={handleGeocoderSelection}
              placeholder="City, Neighborhood or Postal Code of Interest"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '40px',
              }}
            /> */}
          </FlexDiv>
          <FlexDiv
            style={{ marginTop: 10, flexWrap: 'nowrap' }}
            className="noWrapContact"
          >
            <CurrencyFormat
              // label="Minimum Price ($)"
              name="minPrice"
              placeholder="Minimum Price ($)"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '35px',
                width: '50%',
                paddingLeft: '15px',
              }}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={values =>
                handleChange([
                  { name: 'minPrice', value: values.formattedValue },
                ])
              }
            />
            <CurrencyFormat
              altTheme
              // label="Maximum Price ($)"
              // type="number"
              name="maxPrice"
              placeholder="Maximum Price ($)"
              className="contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
                height: '35px',
                width: '50%',
                paddingLeft: '15px',
              }}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={values =>
                handleChange([
                  { name: 'maxPrice', value: values.formattedValue },
                ])
              }
              // onChange={handleChangeEvent}
              // onValueChange={handleChangeEvent}
            />
          </FlexDiv>
          <FlexDiv
            style={{
              marginTop: 10,
              flexWrap: 'nowrap',
              placeContent: 'space-between',
            }}
            className="noWrapContact"
          >
            <Select
              options={bedOptions}
              name="minBeds"
              placeholder="Minimum # of Beds"
              onChange={handleSelect}
              className="signUpDropDownWidth"
              classNamePrefix="filter"
              components={{ DropdownIndicator }}
            />
            <Select
              options={bathOptions}
              name="minBaths"
              placeholder="Minimum # of Baths"
              onChange={handleSelect}
              className="signUpDropDownWidth"
              classNamePrefix="filter"
              components={{ DropdownIndicator }}
            />
          </FlexDiv>
          <div>
            <div
              className="contactInputWrapper"
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '7.5px',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  id="yes"
                  // name="underContract"
                  name="agreeToReceiveEmails"
                  checked={formData.agreeToReceiveEmails === true}
                  // value={'yes'}
                  className="signUpInputCheckbox"
                  onChange={() =>
                    setFormData({
                      ...formData,
                      agreeToReceiveEmails: !formData.agreeToReceiveEmails,
                    })
                  }
                />
                <RadioLabel
                  for="yes"
                  style={{ fontSize: '13.5px', color: '#000' }}
                >
                  I agree to receieve emails from Ellicott Realty and understand
                  that I may opt out at any time.
                </RadioLabel>
              </div>
              <div
                style={{
                  marginTop: '1em',
                  display: 'flex',
                  textAlign: 'start',
                }}
              >
                <input
                  type="checkbox"
                  id="yes"
                  name="underContract"
                  checked={formData.tosAgreement === true}
                  // value={'no'}
                  className="signUpInputCheckbox checkbox2"
                  onChange={() =>
                    setFormData({
                      ...formData,
                      tosAgreement: !formData.tosAgreement,
                    })
                  }
                />
                <RadioLabel
                  for="no"
                  style={{ fontSize: '13.5px', color: '#000' }}
                >
                  By proceeding, I agree to Ellicott Realty's{' '}
                  <Link> Terms of Service </Link> and acknowledge that I have
                  read the privacy policy.
                </RadioLabel>
              </div>
            </div>
          </div>
          <FlexDiv style={{ marginTop: 10 }}>
            <Input
              name="message"
              label="Question / Message:"
              lines={5}
              className="myInput contactFormInput"
              style={{
                marginRight: 5,
                background: '#fff',
                color: '#000',
                border: '1px solid lightgray',
              }}
              onChange={handleChangeEvent}
            />
          </FlexDiv>
          <FlexDiv vert style={{ margin: '0 0.5em' }}>
            <FlexDiv vert>
              <FlexDiv>
                <ButtonSubscribe
                  style={{
                    marginTop: 20,
                    fontFamily: 'Montserrat-Bold',
                    letterSpacing: '0px',
                    padding: '10px',
                    fontSize: '1.1em',
                  }}
                  onClick={signupButtonHandler}
                  disabled={
                    !formData.agreeToReceiveEmails || !formData.tosAgreement
                  }
                >
                  NEXT
                </ButtonSubscribe>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </div>
  )
}
