export const serializeAsQueryString = (obj: { [key: string]: any }) => {
  const str = []
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      if (obj[p] instanceof Array) {
        obj[p].forEach((item: string) => {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(item))
        })
      } else {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    }
  return str.join('&')
}
