import { ListingType } from '@services/repliers/repliers-services.type'
import axios from 'axios'

export type ContactFormDataType = {
  fname?: string
  lname?: string
  name?: string
  email: string
  phone?: string
  underContract?: string
  // contact date (home page)
  date?: string
  // which developments are you interested in? (developments)
  interestedDevelopments?: string
  // which properties are you interested in? (contact)
  interestedProperties?: string
  // register page form info (register)
  bounds?: [number, number][]
  preferences?: string
  minPrice?: number
  maxPrice?: number
  minBeds?: number
  maxBeds?: number
  minBaths?: number
  maxBaths?: number
  agreeToReceiveEmails?: boolean
  tosAgreement?: boolean
  dates?: [string, string]
  timeslot?: string
  additionalDetails?: string
  listing?: ListingType
  message?: string
}
export type ContactFormReturnType = {
  success: boolean
  msg: string
}

type ConfigureSendContactServiceType = (
  URL: string,
) => (formData: ContactFormDataType) => Promise<ContactFormReturnType>

export const configureSendContactFormService: ConfigureSendContactServiceType = URL => formData => {
  const headers = {
    'content-type': 'application/json',
  }

  return axios
    .post<ContactFormReturnType>(URL, formData, { headers })
    .then(res => ({
      success: res.data.success,
      msg: res.data.msg,
    }))
}
