import axios from 'axios'
import { ListingType } from './repliers-services.type'
import { serializeAsQueryString } from 'utils'
import { gmapsBoundsToRepliersMapString } from 'utils/gmaps'
import Cookies from 'js-cookie'
import {
  AURORA_BOUNDS,
  BRAMPTON_BOUNDS,
  MARKHAM_BOUNDS,
  MILTON_BOUNDS,
  SCARBOROUGH_BOUNDS,
  MISSISSAUGA_BOUNDS,
  NEW_MARKET_BOUNDS,
  OAKVILLE_BOUNDS,
  RICHMOND_HILL_BOUNDS,
  WHITCHURCH_STOUFFVILLE_BOUNDS,
  ETOBICOKE_BOUNDS,
  GEORGETOWN_BOUNDS,
  GUELPH_BOUNDS,
  NORTHYORK_BOUNDS,
  VAUGHAN_BOUNDS,
  TORONTO_BOUNDS,
  PICKERING_BOUNDS,
  KINGCITY_BOUNDS,
  STCATHARINES_BOUNDS,
} from '.'
export type GetListingsOptionsType = {
  page: number
  minPrice?: number
  maxPrice?: number
  minBeds?: number
  maxBeds?: number
  minBaths?: number
  maxBaths?: number
  minSqft?: number
  maxSqft?: number
  class?: string
  listDate?: string
  updatedOn?: string
  keywords?: string
  bounds?: google.maps.LatLngBounds
  customBounds?: [[number, number]]
  propertyType?: string[]
  sortBy: string
  type?: string
  hasImages: boolean
  resultsPerPage: number
  label?: string
}

export const buildQueryFromOptions = (
  options: GetListingsOptionsType,
  defaultCity: string,
) => {
  const serviceOptions: { [key: string]: any } = {}
  if (options.bounds !== undefined) {
    //code below throwing bound error
    const bounds = gmapsBoundsToRepliersMapString(options.bounds)
    const label = options.label
    const splitLabel = label?.split(',')
    if (splitLabel?.includes('Mississauga')) {
      serviceOptions.map = JSON.stringify([MISSISSAUGA_BOUNDS])
    } else if (splitLabel?.includes('Oakville')) {
      serviceOptions.map = JSON.stringify([OAKVILLE_BOUNDS])
    } else if (splitLabel?.includes('Toronto')) {
      serviceOptions.map = JSON.stringify([TORONTO_BOUNDS])
    } else if (splitLabel?.includes('Pickering')) {
      serviceOptions.map = JSON.stringify([PICKERING_BOUNDS])
    } else if (splitLabel?.includes('Milton')) {
      serviceOptions.map = JSON.stringify([MILTON_BOUNDS])
    } else if (splitLabel?.includes('Brampton')) {
      serviceOptions.map = JSON.stringify([BRAMPTON_BOUNDS])
    } else if (splitLabel?.includes('St.Catharines')) {
      serviceOptions.map = JSON.stringify([STCATHARINES_BOUNDS])
    } else if (splitLabel?.includes('KingCity')) {
      serviceOptions.map = JSON.stringify([KINGCITY_BOUNDS])
    } else if (
      splitLabel?.includes('Richmond') &&
      splitLabel?.includes('Hill')
    ) {
      serviceOptions.map = JSON.stringify([RICHMOND_HILL_BOUNDS])
    } else if (splitLabel?.includes('King') && splitLabel?.includes('City')) {
      serviceOptions.map = JSON.stringify([KINGCITY_BOUNDS])
    } else if (splitLabel?.includes('Markham')) {
      serviceOptions.map = JSON.stringify([MARKHAM_BOUNDS])
    } else if (splitLabel?.includes('Whitchurch-Stouffville')) {
      serviceOptions.map = JSON.stringify([WHITCHURCH_STOUFFVILLE_BOUNDS])
    } else if (splitLabel?.includes('Aurora')) {
      serviceOptions.map = JSON.stringify([AURORA_BOUNDS])
    } else if (splitLabel?.includes('Newmarket')) {
      serviceOptions.map = JSON.stringify([NEW_MARKET_BOUNDS])
    } else if (splitLabel?.includes('Georgetown')) {
      serviceOptions.map = JSON.stringify([GEORGETOWN_BOUNDS])
    } else if (splitLabel?.includes('Guelph')) {
      serviceOptions.map = JSON.stringify([GUELPH_BOUNDS])
    } else if (splitLabel?.includes('Etobicoke')) {
      serviceOptions.map = JSON.stringify([ETOBICOKE_BOUNDS])
    } else if (splitLabel?.includes('Vaughan')) {
      serviceOptions.map = JSON.stringify([VAUGHAN_BOUNDS])
    } else if (splitLabel?.includes('Scarborough')) {
      serviceOptions.map = JSON.stringify([SCARBOROUGH_BOUNDS])
    } else if (splitLabel?.includes('North-York')) {
      serviceOptions.map = JSON.stringify([NORTHYORK_BOUNDS])
    } else if (splitLabel?.includes('Toronto')) {
      serviceOptions.map = JSON.stringify([TORONTO_BOUNDS])
    } else {
      serviceOptions.map = JSON.stringify([])
      console.log(options)
      Cookies.set('gmapbounds', bounds)
      serviceOptions.map = bounds
    }
  } else {
    // console.log('Load default bound options')
    // console.log(defaultCity)
    // const bounds = gmapsBoundsToRepliersMapString(options.bounds)
    // const label = options.label
    // const splitLabel = label?.split(',')
    if (defaultCity == 'Mississauga') {
      serviceOptions.map = JSON.stringify([MISSISSAUGA_BOUNDS])
    } else if (defaultCity == 'Oakville') {
      serviceOptions.map = JSON.stringify([OAKVILLE_BOUNDS])
    } else if (defaultCity == 'St.Catharines') {
      serviceOptions.map = JSON.stringify([STCATHARINES_BOUNDS])
    } else if (defaultCity == 'Milton') {
      serviceOptions.map = JSON.stringify([MILTON_BOUNDS])
    } else if (defaultCity == 'Brampton') {
      serviceOptions.map = JSON.stringify([BRAMPTON_BOUNDS])
    } else if (defaultCity == 'Richmond Hill') {
      serviceOptions.map = JSON.stringify([RICHMOND_HILL_BOUNDS])
    } else if (defaultCity == 'Markham') {
      serviceOptions.map = JSON.stringify([MARKHAM_BOUNDS])
    } else if (defaultCity == 'Whitchurch-Stouffville') {
      serviceOptions.map = JSON.stringify([WHITCHURCH_STOUFFVILLE_BOUNDS])
    } else if (defaultCity == 'Aurora') {
      serviceOptions.map = JSON.stringify([AURORA_BOUNDS])
    } else if (defaultCity == 'Newmarket') {
      serviceOptions.map = JSON.stringify([NEW_MARKET_BOUNDS])
    } else if (defaultCity == 'Georgetown') {
      serviceOptions.map = JSON.stringify([GEORGETOWN_BOUNDS])
    } else if (defaultCity == 'Guelph') {
      serviceOptions.map = JSON.stringify([GUELPH_BOUNDS])
    } else if (defaultCity == 'Etobicoke') {
      serviceOptions.map = JSON.stringify([ETOBICOKE_BOUNDS])
    } else if (defaultCity == 'Vaughan') {
      serviceOptions.map = JSON.stringify([VAUGHAN_BOUNDS])
    } else if (defaultCity == 'Scarborough') {
      serviceOptions.map = JSON.stringify([SCARBOROUGH_BOUNDS])
    } else if (defaultCity == 'North-York') {
      serviceOptions.map = JSON.stringify([NORTHYORK_BOUNDS])
    } else if (defaultCity == 'Toronto') {
      serviceOptions.map = JSON.stringify([TORONTO_BOUNDS])
    } else if (defaultCity == 'Pickering') {
      serviceOptions.map = JSON.stringify([PICKERING_BOUNDS])
    } else if (defaultCity == 'King City') {
      serviceOptions.map = JSON.stringify([KINGCITY_BOUNDS])
    } else if (defaultCity == 'KingCity') {
      serviceOptions.map = JSON.stringify([KINGCITY_BOUNDS])
    }
  }
  if (options.page !== undefined) {
    serviceOptions.pageNum = options.page
  }
  if (options.minPrice !== undefined) {
    serviceOptions.minPrice = options.minPrice
  }
  if (options.maxPrice !== undefined) {
    serviceOptions.maxPrice = options.maxPrice
  }
  if (options.minBeds !== undefined) {
    serviceOptions.minBeds = options.minBeds
  }
  if (options.maxBeds !== undefined) {
    serviceOptions.maxBeds = options.maxBeds
  }
  if (options.minBaths !== undefined) {
    serviceOptions.minBaths = options.minBaths
  }
  if (options.maxBaths !== undefined) {
    serviceOptions.maxBaths = options.maxBaths
  }
  if (options.minSqft !== undefined) {
    serviceOptions.minSqft = options.minSqft
  }
  if (options.maxSqft !== undefined) {
    serviceOptions.maxSqft = options.maxSqft
  }
  if (options.keywords !== undefined) {
    serviceOptions.keywords = options.keywords
  }
  if (options.propertyType !== undefined) {
    serviceOptions.propertyType = options.propertyType
  }
  if (options.listDate !== undefined) {
    serviceOptions.listDate = options.listDate
  }

  if (options.type != undefined) {
    serviceOptions.type = options.type
  }

  if (options.class != undefined) {
    serviceOptions.class = options.class
  }
  if (options.resultsPerPage != undefined) {
    serviceOptions.resultsPerPage = options.resultsPerPage
  }

  return serviceOptions
}

export const configureGetListingsService = (
  BASE_URL: string,
  API_KEY: string,
) => (options: GetListingsOptionsType, defaultCity: string) => {
  const headers = {
    'content-type': 'application/json',
    'REPLIERS-API-KEY': API_KEY,
  }

  type ListingsReturnType = {
    listings: ListingType[]
    page: number
    numPages: number
    pageSize: number
    count: number
  }

  //if options = residential condo => hardcode query options class ?class=residential&class=condo
  //message pat from repliers to update api
  let url = `${BASE_URL}/listings?${serializeAsQueryString(
    buildQueryFromOptions(options, defaultCity),
  )}`

  if (options.class == 'residential condo') {
    url = `${BASE_URL}/listings?class=residential&class=condo&${serializeAsQueryString(
      buildQueryFromOptions({ ...options, class: undefined }, defaultCity),
    )}`
  }

  if (options.class == 'commercial') {
    url = `${BASE_URL}/listings?class=commercial&${serializeAsQueryString(
      buildQueryFromOptions({ ...options, class: undefined }, defaultCity),
    )}`
  }

  console.log(url)
  return axios
    .get<ListingsReturnType>(url, { headers })
    .then(res => ({
      data: res.data.listings,
      page: res.data.page,
      numPages: res.data.numPages,
      pageSize: res.data.pageSize,
      count: res.data.count,
    }))
}
