import axios from 'axios'
import { ListingType } from './repliers-services.type'

export type GetListingDetailOptionsType = {
  mlsNumber: string
}

export const configureGetListingDetailService = (
  BASE_URL: string,
  API_KEY: string,
) => (options: GetListingDetailOptionsType) => {
  const headers = {
    'content-type': 'application/json',
    'REPLIERS-API-KEY': API_KEY,
  }

  return axios
    .get<ListingType>(`${BASE_URL}/listings/${options.mlsNumber}`, { headers })
    .then(res => res.data)
}
