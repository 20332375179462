import axios from 'axios'

export type Search = {}

export type CreateSearchOptions = {
  clientId: number
  agentId: number
  map: [number, number][][]
  type: 'sale' | 'lease'
  class: string[]
  minPrice: number
  maxPrice: number
  minBeds: number
  maxBeds: number
  minBaths: number
  maxBaths: number
  keywords: string
}

export const configureCreateSearchService = (
  BASE_URL: string,
  API_KEY: string,
) => (data: CreateSearchOptions) => {
  const headers = {
    'content-type': 'application/json',
    'REPLIERS-API-KEY': API_KEY,
  }

  type CreateSearchReturnType = Search

  return axios
    .post<CreateSearchReturnType>(`${BASE_URL}/searches`, data, { headers })
    .then(res => res.data)
}
