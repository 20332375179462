import axios from 'axios'

export type Client = {
  clientId: number
  agentId: number
  fname: string
  lname: string
  phone: string
  email: string
  status: boolean
  lastActivity?: string
  preferences: {
    email: boolean
    sms: boolean
    unsubscribe: boolean
  }
}

export type CreateClientOptions = {
  agentId: number
  fname?: string
  lname?: string
  phone?: string
  email: string
  status: boolean
  preferences: {
    email: boolean
    sms: boolean
    unsubscribe: boolean
  }
}

export const configureCreateClientService = (
  BASE_URL: string,
  API_KEY: string,
) => (clientData: CreateClientOptions) => {
  const headers = {
    'content-type': 'application/json',
    'REPLIERS-API-KEY': API_KEY,
  }

  return axios
    .post<Client>(`${BASE_URL}/clients`, clientData, {
      headers,
    })
    .then(res => {
      return res.data
    })
}
