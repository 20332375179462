import firebase from 'firebase'
import { FirebaseConfigType } from 'config/config.type'
import { FirebaseServicesType } from './firebase-services.type'

export const configureFirebaseServices: (
  firebaseConfig: FirebaseConfigType,
) => FirebaseServicesType = firebaseConfig =>
  firebase.initializeApp({
    apiKey: firebaseConfig.API_KEY,
    authDomain: firebaseConfig.AUTH_DOMAIN,
    databaseURL: firebaseConfig.DATABASE_URL,
    projectId: firebaseConfig.PROJECT_ID,
    storageBucket: firebaseConfig.STORAGE_BUCKET,
    messagingSenderId: firebaseConfig.MESSAGE_SENDER_ID,
    appId: firebaseConfig.APP_ID,
    measurementId: firebaseConfig.MEASUREMENT_ID,
  })

export { FirebaseServicesType }
