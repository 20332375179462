import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexDiv } from 'design-system/FlexDiv'
import { services } from '@services'
import { Link } from 'gatsby'

import { SignUp, SignupComponentProps } from 'components/SignUp'
import Modal from 'react-modal'
import { LoginComponentProps } from 'components/LoginComponent'


import { appConfig } from 'config'
import 'react-toastify/dist/ReactToastify.css'


const TitleH1 = styled.h1`
  font-family: CentraNo2-Bold;
  font-size: 3em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased;
`

const SubHeader = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #000;
  -webkit-font-smoothing: antialiased;
`

const SubTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 1.75em;
  font-stretch: normal;
  font-style: normal;
  color: #000;
  -webkit-font-smoothing: antialiased;
`
const Label = styled.span`
  font-family: CentraNo2-Book;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: #000;
  -webkit-font-smoothing: antialiased;
`

const LandingInput = styled.input`
  background: transparent;
  border: 2px solid #adadad;
  font-family: CentraNo2-Book;
  -webkit-font-smoothing: antialiased;
  width: 80%;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: CentraNo2-Book;
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  color: #000;
  -webkit-font-smoothing: antialiased;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 50%;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`
type HeaderPropsType = {
  onLogout: () => void
}

export const Footer2 = () => {
  const [loginIsOpen, setLoginIsOpen] = React.useState(false)
  const [signupIsOpen, setSignupIsOpen] = React.useState(false)

  const openLogin = () => {
    setLoginIsOpen(true)
  }

  const closeLogin = () => {
    setLoginIsOpen(false)
  }

  const openSignup = () => {
    setSignupIsOpen(true)
  }

  const closeSignup = () => {
    setSignupIsOpen(false)
  }

  const loginUser: LoginComponentProps['handleLoginUser'] = ({
    credentials,
    rememberMe,
  }) => {
    services.firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(closeLogin)
  }

  const createClientInRepliers = (input: {
    email: string
    fname: string
    lname: string
    phonenumber: string
    agreeToReceiveEmails?: boolean
  }) => {
    console.log(input)
    return services.repliers.createClient({
      agentId: parseInt(appConfig.repliers.DEFAULT_AGENT_ID, 10),
      fname: input.fname,
      lname: input.lname,
      phone: '1' + input.phonenumber,
      email: input.email,
      status: true,
      preferences: {
        email: true,
        sms: true,
        unsubscribe: false,
      },
    })
  }

  const createClientSearchInRepliers = (
    clientId: number,
    agentId: number,
    map: [number, number][],
    minPrice: number,
    maxPrice: number,
    minBeds: number,
    maxBeds: number,
    minBaths: number,
    maxBaths: number,
    keywords: string,
  ) =>
    services.repliers.createSearch({
      agentId,
      clientId,
      map: [map],
      type: 'sale',
      class: ['condo', 'residential'],
      minPrice,
      maxPrice,
      minBeds,
      maxBeds,
      minBaths,
      maxBaths,
      keywords,
    })

  const signupUser: SignupComponentProps['handleSignupUser'] = formData => {
    console.log(formData)
    services.firebase
      .auth()
      .createUserWithEmailAndPassword(formData.email, formData.password)
      .then(() => {
        createRepliersClientAndSearch(formData)
      })
      .then(() => {
        toast.success(
          'Preferred client registration success. Thank you for choosing Ellicot',
        )
        const propertyTypeStrings = formData.propertyTypes
          .map(e => e.label)
          .join(',')
        console.log(propertyTypeStrings)
        services.lifted.sendRegisterForm({
          ...formData,
          propertyTypes: propertyTypeStrings,
        })
      })
      .catch(err => {
        console.log(err)
        switch (err.code) {
          case 'auth/email-already-in-use':
            toast.error(
              'The email provided is already registered with us. If you believe this is an error please contact support',
            )

            break
          case 'auth/invalid-email':
            toast.error(
              'The provided value for the email user property is invalid. It must be a string email address.',
            )
          case 'auth/invalid-password':
            toast.error(
              'The provided value for the password user property is invalid. It must be a string with at least six characters.',
            )
          default:
            toast.error('Unexpected error please contact support')

            break
        }
      })
  }

  const createRepliersClientAndSearch: SignupComponentProps['handleSignupUser'] = formData => {
    return createClientInRepliers(formData)
      .then(c =>
        formData.bounds
          ? createClientSearchInRepliers(
              c.clientId,
              parseInt(appConfig.repliers.DEFAULT_AGENT_ID, 10),
              formData.bounds,
              formData.minPrice,
              formData.maxPrice,
              formData.beds,
              formData.beds,
              formData.baths,
              formData.baths,
              formData.keywords,
            )
          : Promise.resolve(c),
      )
      .then(() => setSignupIsOpen(false))
      // .catch(err => {
      //   toast.error(
      //     'We could not add you to our preferred client mailing list. Please login and try again.',
      //   )
      // })
  }

  const [date, setDate] = useState<string>('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    underContract: 'no',
  })
  const [loading, setLoading] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newForm = {
      ...formData,
      [name]: value,
    }

    setFormData(newForm)
  }
  const submitForm = async () => {
    if (loading) {
      return
    }

    const body = {
      ...formData,
      date,
    }
    setLoading(true)
    const res = await services.lifted.sendHomeForm(body)
    if (res) {
      setLoading(false)
    }
  }



  return (
    
    <FlexDiv className="footerParralaxWrapper">
    <FlexDiv className="footerWrap">
      <FlexDiv>
        <ul>  
          <li> <Link to="/developments "style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> New Developments</Link></li>
          <li> <Link to="/properties" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Looking to Buy</Link></li>
          <li> <Link to="/advantage" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Looking to Sell</Link></li>
          <li onClick={openSignup}> <Link style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer'}}><span> &#8250; </span> Register Now</Link></li> 
          <li> <span> &#8250; </span><a href="https://login.ellicottrealty.ca/" target="_blank" style={{ color: 'inherit', textDecoration: 'none'}} onClick={openSignup}
                  className=""> Login</a></li>
        </ul>
      </FlexDiv>
      <FlexDiv className="flexColumn">
       <ul>
          <li> <Link to="/about" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> About Us</Link></li>
          <li> <Link to="/team" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Our Team</Link></li>
          <li> <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Contact Us</Link></li>
          <li> <Link to="/termsservice" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Terms of Service</Link></li>
          <li> <Link to="/privacypolicy" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Privacy Policy </Link></li> 
        </ul>
      </FlexDiv>
      <FlexDiv className="flexColumn">
        <ul>
            <li> <Link to="/toronto" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Toronto</Link></li>
            <li> <Link to="/vaughan" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Vaughan</Link></li>
            <li> <Link to="/etobicoke" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Etobicoke</Link></li>
            <li> <Link to="/markham "style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Markham</Link></li>
            <li> <Link to="/aurora" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Aurora</Link></li>
        </ul>
      </FlexDiv>
      <FlexDiv className="flexColumn">
         <ul>
            <li> <Link to="/newmarket" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Newmarket</Link></li>
            <li> <Link to="/stouffville" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Stoufville</Link></li>
            <li> <Link to="/kingcity" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in King City</Link></li>
            <li> <Link to="/pickering" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in Pickering</Link></li>
            <li> <Link to="/stcaths" style={{ color: 'inherit', textDecoration: 'none'}}><span> &#8250; </span> Search in St. Catharines</Link></li>
          </ul>
      </FlexDiv>
      </FlexDiv>
      <Modal isOpen={signupIsOpen} onRequestClose={closeSignup} className={'Modal23'}>
                  <SignUp
                    handleSignupUser={signupUser}
                    setSignupIsOpen={setSignupIsOpen}
                  />
      </Modal>
</FlexDiv>
  )
}
